<template>
	<div v-if="isLoaded" class="page-content">
		<van-row class="prev-box">
			<van-col span="4" @click="onClickLeft()">
				<div class="prev-left">&lt;上一题</div>
			</van-col>
			<van-col span="16">
				<van-progress
					:percentage="(index / total) * 100"
					:show-pivot="false"
					class="prev-center"
				/>
			</van-col>
			<van-col span="4">
				<div class="prev-right">{{ index }} / {{ total }}</div>
			</van-col>
		</van-row>

		<h3 class="title">{{ appraisalList[index - 1]['questionName'] }}</h3>
		<p class="title-desc">
			<span style="color: #1890FF;">{{
				'（' + appraisalList[index - 1]['itemName'] + '）'
			}}</span>
			{{ appraisalList[index - 1]['standardName'] }}
		</p>

		<div class="huida-box">
			<div
				class="huida-item"
				v-bind:class="{ 'huida-item huida-item-select': radio == '1' }"
				@click="selectItem('1')"
			>
				<div class="n">是</div>
				<div class="v"></div>
			</div>
			<div
				class="qingshuru"
				v-if="appraisalList[index - 1]['questionType'] === 2 && radio == 1"
			>
				<label>完成的数量</label>
				<input
					v-model="digit"
					type="number"
					class="cishuinput"
					placeholder="请输入数字"
				/>
				<label style="margin-right: 0">{{
					appraisalList[index - 1]['questionUnit']
				}}</label>
			</div>

			<div
				class="huida-item"
				v-bind:class="{ 'huida-item huida-item-select': radio == '-1' }"
				@click="selectItem('-1')"
			>
				<div class="n">否</div>
				<div class="v"></div>
			</div>
		</div>
		<div class="btn-beizhu" @click="showbeizhu = 1">添加备注</div>
		<textarea
			v-model="note"
			v-if="showbeizhu"
			class="beizhutxt"
			placeholder="请输入备注信息"
		></textarea>

		<!-- <van-radio-group v-model="radio" class="huida-box">
      <van-row>
        <van-col span="20">
          <div>是</div>
        </van-col>
        <van-col>
          <van-radio name="1"></van-radio>
        </van-col>
      </van-row>
      <van-field
        v-if="appraisalList[index - 1]['questionType'] == 2 && 1 == radio"
        v-model="digit"
        type="digit"
        label=""
      />
      <van-row>
        <van-col span="20">
          <div>否</div>
        </van-col>
        <van-col span="4">
          <van-radio name="-1"></van-radio>
        </van-col>
      </van-row>
      <van-field
        v-model="note"
        rows="4"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入备注"
      />
    </van-radio-group> -->

		<van-button round block class="okbtn" @click="nextStep()"
			>{{ '下一步' }}
		</van-button>
	</div>
</template>

<script>
import {
	Row,
	Col,
	Icon,
	Cell,
	CellGroup,
	NavBar,
	Button,
	Progress,
	RadioGroup,
	Radio,
	Field,
	Dialog,
	Toast
} from 'vant';
import { mapMutations, mapGetters } from 'vuex';
import { getListByUserid, saveQuestions } from '@/api/user';

export default {
	components: {
		[Row.name]: Row,
		[Col.name]: Col,
		[Icon.name]: Icon,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[NavBar.name]: NavBar,
		[Button.name]: Button,
		[Progress.name]: Progress,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Field.name]: Field,
		[Dialog.name]: Dialog
	},
	computed: {
		...mapGetters({
			userInfo: 'userInfo',
			appraisalList: 'appraisalList',
			answerList: 'answerList',
			month: 'month',
			reSubmitRecordMonth: 'reSubmitRecordMonth',
			reSubmitRecordId: 'reSubmitRecordId'
		}),
		total: function() {
			return this.appraisalList.length;
		}
	},
	data() {
		return {
			index: 1,
			// total: 10,
			radio: '1',
			digit: '',
			note: '',
			isLoaded: false,
			showbeizhu: false //是否显示备注
		};
	},
	watch: {
		$route: function() {
			this.index = this.$route.query.index;
			let isExist = this.answerList.some((item) => {
				return item.id == this.appraisalList[this.index - 1]['id'];
			});
			if (isExist) {
				console.log('已存在记录');
				this.radio = this.answerList[this.index - 1]['radio'].toString();
				this.digit = this.answerList[this.index - 1]['digit'];
				this.note = this.answerList[this.index - 1]['note'];
				this.showbeizhu = this.answerList[this.index - 1]['note']
					? true
					: false;
			} else {
				console.log('不存在记录');
				this.radio = '1';
				this.digit = '';
				this.note = '';
				this.showbeizhu = false;
			}
			// this.$forceupdate()
		}
	},
	created() {
		console.log(this.userInfo.id);
		getListByUserid({
			staffsId: this.userInfo.id
		}).then((res) => {
			console.log(res);
			if (res.data.code == 200) {
				this.isLoaded = true;
				this.SET_APPRAISALLIST(res.data.data);
				console.log(this.appraisalList);
			} else {
				alert(res.data.msg);
			}
		});
	},
	methods: {
		//选中答案的操作
		selectItem(v) {
			console.log('我选中的值是：', v);
			this.radio = v; //设置值
		},
		...mapMutations('user', ['SET_APPRAISALLIST']),
		onClickLeft() {
			this.$router.back(-1);
		},
		nextStep() {
			if (
				this.radio == 1 &&
				this.appraisalList[this.index - 1]['questionType'] == 2
			) {
				if (
					this.digit.length < 1 ||
					isNaN(this.digit) ||
					Number(this.digit) < 1
				) {
					alert('完成数量必须为正整数');
					return;
				}
			}
			let isExist = this.answerList.some((item) => {
				return item.id == this.appraisalList[this.index - 1]['id'];
			});
			if (isExist) {
				console.log('已存在记录');
				this.answerList[this.index - 1]['radio'] = Number(this.radio);
				this.answerList[this.index - 1]['digit'] = this.digit;
				this.answerList[this.index - 1]['note'] = this.note;
			} else {
				console.log('新记录');
				this.answerList.push({
					id: this.appraisalList[this.index - 1]['id'],
					radio: Number(this.radio),
					digit: this.digit.length < 1 ? '' : Number(this.digit),
					note: this.note
				});
			}
			// this.answerList.forEach((item) => {
			//   if (item.id == this.appraisalList[this.index - 1]['id']) {
			//     console.log('已存在记录');
			//   } else {
			//     console.log('新存在记录');
			//     this.answerList.push({
			//       id: this.appraisalList[this.index - 1]['id'],
			//       radio: this.radio,
			//       digit: this.digit,
			//       note: this.note
			//     })
			//   }
			// })
			console.log(this.answerList);
			if (this.index < this.total) {
				this.index++;
				// this.radio = '1'
				// this.digit = ''
				// this.note = ''
				this.$router.push({
					path: '/step',
					query: {
						index: this.index
					}
				});
			} else {
				let info = {
					assessmentId: this.userInfo.assessment.id,
					superiorId: this.userInfo.superiorId,
					staffsId: this.userInfo.id,
					answerList: this.answerList,
					month: this.month
				};
				console.log(info);
				Dialog.confirm({
					message: '是否提交'
				})
					.then(() => {
						Toast.loading({
							message: '请稍等...',
							forbidClick: true
						});
						// on confirm
						saveQuestions(info).then((res) => {
							console.log(res);
							Toast.clear();
							if (res.data.code == 200) {
								this.$router.push({ name: 'stepComplete' });
							} else {
								Toast.fail(res.data.msg);
							}
						});
						// window.opener = null
						// window.open('about:blank', '_top').close()
					})
					.catch(() => {
						// on cancel
						Toast.fail('网络超时，请重新提交');
					});
			}
		}
	}
};
</script>
<style src="../../static/oa-img/css/step.css" scoped></style>

<style lang="less" scoped></style>
